import React from "react";
import { useSelector } from "react-redux";

import { Payment } from "@getpickedupgpu/gpu2.shared";
import { selectUser } from "@store/user/selector";
import { DEFAULT_URL, config } from "@constants/config";
import { WelcomeBack } from "@components";
import { useNavigate } from "react-router-dom";
import { BOOKING_ROUTE } from "@constants/routes";

const PaymentPage = (): JSX.Element => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  return (
    <Payment
      user={user}
      config={{
        ...config,
        default_url: DEFAULT_URL
      }}
      extra={<WelcomeBack />}
      onUpdate={() => {
        navigate(`/${BOOKING_ROUTE}`);
      }}
    />
  );
};

export default PaymentPage;
